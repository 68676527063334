import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import IceCreamCup from "../images/svgs/ice-cream-cup.svg"
import Donut from "../images/svgs/mini-donut.svg"
import Gallery from "../components/gallery"
import Map from "../components/googleMap"
import Headline from "../components/headline"

import StoryShape from "../images/svgs/ourStoryShape.svg"

export default function Home({ data }) {
  //Wordpress data
  const array = data.allWpPage.edges[0].node.ourStory || []

  //Our Story
  const ourStoryHeader = array ? array.ourStoryPage.header : "Our Story"
  const ourStoryTxt = array ? array.ourStoryPage.text : "Donut Worry"
  const ourStoryImage = array.ourStoryPage.image
    ? array.ourStoryPage.image.sourceUrl
    : ""
  const ourStoryAltText = array.ourStoryPage.image
    ? array.ourStoryPage.image.altText
    : ""

  //Gallery Header
  const galleryHeader = array.galleryHeader || "Life’s Sweet"

  return (
    <Layout>
      <SEO title="Our Story" />
      <section className="ourStory mt-20">
        <div className="container">
          <Headline
            title={ourStoryHeader}
            icon={<Donut className="invisible md:visible h-auto" />}
          />
          <div className="flex flex-wrap">
            <div className="story-images relative :mt-8 lg:mt-0 w-full lg:w-1/2">
              <img
                className="pr-16 mt-8 lg:-mt-8 z-1 relative pb-8 "
                src={ourStoryImage}
                alt={ourStoryAltText}
              />
            </div>
            <div className="story-cont lg:mt-16 w-full lg:w-1/2">
              <p>{ourStoryTxt}</p>
            </div>
          </div>
        </div>
        <Donut
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="mini-donut primary-color invisible md:visible"
        />
      </section>
      <section className="gallery overflow-hidden mt-20 md:mt-44">
        <div className="container gallery-cont overflow-visble mx-auto">
          <Headline
            title={galleryHeader}
            icon={<IceCreamCup className="invisible md:visible h-auto" />}
          />
          <Gallery />
        </div>
      </section>
      <section className="location mt-20">
        <Headline
          title="Our Locations"
          icon={<Donut className="invisible md:visible h-auto" />}
        />
        <Map />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query OurStoryPageQuery {
    allWpPage(filter: { id: { eq: "cG9zdDozMA==" } }) {
      edges {
        node {
          ourStory {
            galleryHeader
            ourStoryPage {
              header
              image {
                altText
                sourceUrl
              }
              text
            }
          }
        }
      }
    }
  }
`
